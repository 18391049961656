<script>
  import appConfig from "@/app.config";
  import store from "@/state/store";
  import { ToggleButton } from "vue-js-toggle-button";
  import Storage from "../../../services/storage";
  var storage = new Storage();
  export default {
    page: {
      title: "Referral Code Settings",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: {
      ToggleButton: ToggleButton,
    },
    data() {
      return {
        errors: "",
        loading: false,
        title: "Copy to clipboard",
        type: "alert-danger",
        formData: {
          referral_status: false,
          referral_code: "",
        },
      };
    },
    mounted() {
      this.getReferral();
      this.getReferralPoints();
      // Analytics
      this.$mixpanel.track("Referral Page");
      if (this.$store.getters["auth/currentUser"].country === "CI") {
        this.$mixpanelFranco.track("Referral Page");
      }
    },
    methods: {
      clickToClipBoard() {
        let copyText = document.getElementById("copyToClipBoard");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        this.title = "Copied!";
      },
      onChangeEventHandler() {
        this.toggleReferralCode();
      },
      getReferral() {
        this.loading = true;
        this.errors = "";
        store
          .dispatch("auth/getReferral", this.formData)
          .then(({ data }) => {
            this.loading = false;
            if (data.data.is_active === 1) {
              this.formData.referral_status = true;
              this.formData.referral_code = data.data.referral_code;
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data == undefined) {
              this.errors = ["Network error try again"];
            }
            if (err.response && err.response.status === 404) {
              this.errors = err.response.data.data.error;
            }
          });
      },
      toggleReferralCode() {
        this.errors = "";
        this.loading = true;
        store
          .dispatch("auth/toggleReferal", {
            referral_status: this.formData.referral_status ? 0 : 1,
          })
          .then((data) => {
            this.$notify({
              title: "Success",
              message: data.data.message,
              type: "success",
            });
            window.location.reload();
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data == undefined) {
              this.errors = "Network error try again";
            }
            if (err.response && err.response.status === 404) {
              this.errors = err.response.data.data.error;
            }
          });
      },
      getReferralPoints() {
        store.dispatch("referrals/getReferralPoints");
      },
    },
    computed: {
      referralPoints() {
        return store.getters["referrals/referralPoints"];
      },
      checkCurrency() {
        const country = storage.getSavedState("currentUser").country;
        if (country == "CI" || country == "SN") {
          return "FCFA";
        } else {
          return "GHS";
        }
      },
    },
  };
</script>
<style scoped>
  #account-settings {
    height: calc(100vh - 120px);
  }

  .profile_form {
    width: 385px;
    margin-left: 3em;
  }

  @media screen and (max-width: 500px) {
    .profile_form {
      width: 100%;
      margin-left: 0px;
    }
  }

  #copyToClipBoard {
    width: 100%;
    margin-bottom: 2em;
    border: 1.5px solid #f7921c !important;
    border-radius: 5px;
    height: 40px;
    padding-left: 1em;
    padding-right: 1em;
  }

  #copyToClipBoard:focus {
    outline: none;
    border: 1.5px solid #f7921c !important;
  }

  .points-description,
  .incentivise {
    font-size: 12px;
    /* celebration color */
    color: #f56c6c;
  }
</style>
<template>
  <div class="col-md-12">
    <div id="account-settings" class="mnotify-card">
      <div id="side-indicators-content" class="mnotify-card-header">
        <div class="profile-settings-section-header">
          <profilesettingsBtn />
          <div class="profile-settings-sub-header">
            <h3 class="mnotify-card-header-text pb-2 my-0">
              {{ $t("dashboard.account_settings.referral_code.title") }}
            </h3>
            <p class="profile-description">
              {{ $t("dashboard.account_settings.referral_code.sub_header") }}
            </p>
          </div>
        </div>
      </div>
      <div class="mnotify-card-body">
        <div
          class="mt-5"
          style="height: 50px"
          v-loading="loading"
          v-if="loading"></div>
        <div v-else class="row profile-settings-form" style="margin-top: 2em">
          <div class="profile_form">
            <div class="profile-inner">
              <p>
                {{
                  formData.referral_status
                    ? "Disable referral code"
                    : "Enable referral code"
                }}
              </p>
              <toggle-button
                :value="formData.referral_status"
                @change="onChangeEventHandler" />

              <div>
                <small class="incentivise"
                  ><em>
                    {{ $t("dashboard.account_settings.referral_code.cta") }}</em
                  ></small
                >
              </div>
              <div class="mt-3" v-if="formData.referral_code">
                <p>
                  {{
                    $t("dashboard.account_settings.referral_code.share_code")
                  }}
                </p>
                <input
                  type="text"
                  :title="title"
                  id="copyToClipBoard"
                  readonly
                  v-model="formData.referral_code" />
                <div class="current-referral-points">
                  <p class="mt-3">
                    {{ $t("dashboard.account_settings.referral_code.amount") }}
                  </p>
                  <p class="mt-3">
                    {{ checkCurrency }}
                    {{
                      Number(referralPoints.total_amount)?.toLocaleString() || 0
                    }}
                  </p>
                  <p class="points-description">
                    <em
                      >{{
                        $t("dashboard.account_settings.referral_code.earn_more")
                      }}
                    </em>
                  </p>
                </div>
              </div>
              <div class="float-right">
                <el-button
                  type="primary"
                  @click="clickToClipBoard"
                  v-if="formData.referral_code">
                  {{ title }}
                </el-button>
              </div>
              <Alert v-if="errors" :message="errors" :type="type" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
